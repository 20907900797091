.page.online-customer-service {
    flex-grow: 1;
    margin-right: 10px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
}

.cs-conversation__content {
    width: 120px;
}

.cs-message.has-footer .cs-message__avatar {
    margin-bottom: 20px;
}

.cs-message__image-content>img {
    max-width: 400px;
}

.quick-reply.group .quick-reply.message {
    display: flex;
    align-items: center;
}

.quick-reply.group .quick-reply.message .quick-reply.btn-rm {
    display: none;
}

.quick-reply.group .quick-reply.message:hover .quick-reply.btn-rm {
    display: block;
}