.page.cms-article-new .editor-review {
  display: flex;
}

.page.cms-article-new .editor-review .editor {
  width: 60%;
  max-width: 800px;
}

.page.cms-article-new .editor-review .editor .actions {
  display: flex;
  justify-content: space-between;
}

.page.cms-article-new .editor-review .editor .column-title {
  font-size: 24px;
}

.page.cms-article-new .editor-review .editor .article-title {
  font-size: 20px;
}

.page.cms-article-new .editor-review .editor #editorjs {
  height: calc(100vh - 255px);
  overflow: auto;
  border: 5px dashed #eee;
  border-radius: 15px;
}

.page.cms-article-new .editor-review .review {
  width: 40%;
  transform: scale(1);
  overflow: auto;
}

.page.cms-article-new .editor-review .review .device-emulator-container {
  align-items: center;
  position: fixed;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame.mobile-frame,
.page.cms-article-new .editor-review .review .device-emulator-container .frame.tab-frame {
  background-color: #fff;
  overflow: auto;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame {
  user-select: none;
  font-size: 16px;
  line-height: 1.7em;
  background-color: rgba(250, 250, 250, 255);
  padding: 10px 0;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame .image-content {
  padding: 10px 0;
  margin: 10px 0;
  border-radius: 5px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame .image-content-bg {
  background-repeat: no-repeat;
  background-position: center;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame .image-content img {
  max-width: 100%;
  visibility: hidden;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame ::selection {
  background: rgba(193, 161, 107, 255);
  color: white;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame>* {
  padding: 0 15px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h1,
.page.cms-article-new .editor-review .review .device-emulator-container .frame h2,
.page.cms-article-new .editor-review .review .device-emulator-container .frame h3,
.page.cms-article-new .editor-review .review .device-emulator-container .frame h4,
.page.cms-article-new .editor-review .review .device-emulator-container .frame h5,
.page.cms-article-new .editor-review .review .device-emulator-container .frame h6,
.page.cms-article-new .editor-review .review .device-emulator-container .frame .title {
  margin: 0;
  padding-bottom: 3px;
  color: rgba(38, 34, 32, 255);
  font-family: "custom-family", serif;
  font-weight: bold;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h1,
.page.cms-article-new .editor-review .review .device-emulator-container .frame .title {
  font-weight: bold;
  font-size: 22px;
  padding-bottom: 3px;
  background-size: 0.5em 0.5em;
  background-image: url("../images/bg.png");
  background-repeat: no-repeat;
  background-position: 0.5em 0.8em;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h2 {
  font-size: 20px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h2::before {
  content: "•";
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h3 {
  font-size: 18px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame h4 {
  font-size: 17px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame .column-title {
  color: #888;
  font-size: 14px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame p {
  margin: 5px 0 10px;
  color: rgba(107, 100, 97, 255);
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame ol,
.page.cms-article-new .editor-review .review .device-emulator-container .frame ul {
  margin: 0;
  padding-left: 20px;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame ol li,
.page.cms-article-new .editor-review .review .device-emulator-container .frame ul li {
  margin: 5px 0 10px;
  color: rgba(107, 100, 97, 255);
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame a {
  color: rgba(193, 161, 107, 255);
  text-decoration: underline;
}

.page.cms-article-new .editor-review .review .device-emulator-container .frame a:active {
  background: rgba(193, 161, 107, 255);
  color: white;
}