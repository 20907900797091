.page.community {
    color: #333;
}

.page.community .post-content {
    padding: 0;
}

.page.community .post-content p {
    margin: 8px 0;
}

.page.community .post-content img {
    width: 100%;
}

.page.community .post-content::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.page.community .post-content::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.community .post-content::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.community .ql-snow .ql-tooltip[data-mode="link"]::before {
    content: "请输入链接地址:";
}

.page.community .ql-snow .ql-tooltip.ql-editing a.ql-action::after {
    border-right: 0px;
    content: "保存";
    padding-right: 0px;
}

.page.community .ql-snow .ql-tooltip[data-mode="video"]::before {
    content: "请输入视频地址:";
}

.page.community .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.page.community .ql-snow .ql-picker.ql-size .ql-picker-item::before {
    content: "14px";
}

.page.community .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.page.community .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    content: "10px";
}

.page.community .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.page.community .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    content: "18px";
}

.page.community .ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.page.community .ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
    content: "32px";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item::before {
    content: "文本";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
    content: "标题1";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
    content: "标题2";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
    content: "标题3";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
    content: "标题4";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
    content: "标题5";
}

.page.community .ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.page.community .ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
    content: "标题6";
}

.page.community .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before,
.page.community .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
    content: "宋体";
}

.page.community .ql-font-SimSun {
    font-family: "SimSun";
}

.page.community .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.page.community .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
    content: "黑体";
}

.page.community .ql-font-SimHei {
    font-family: "SimHei";
}

.page.community .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Microsoft-YaHei"]::before,
.page.community .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Microsoft-YaHei"]::before {
    content: "微软雅黑";
}

.page.community .ql-font-Microsoft-YaHei {
    font-family: "Microsoft YaHei";
}

.page.community .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.page.community .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
    content: "楷体";
}

.page.community .ql-font-KaiTi {
    font-family: "KaiTi";
}

.page.community .ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.page.community .ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
    content: "仿宋";
}

.page.community .ql-font-FangSong {
    font-family: "FangSong";
}

.page.community.router {
    min-width: 1500px;
    max-width: 1600px;
}

.page.community.simulator {
    border: 4px #eee solid;
    border-radius: 10px;
    height: 750px;
    margin: 0 10px;
}

.page.community.simulator.in-review {
    border: none;
    height: auto;
    max-height: 700px;
    min-height: 400px;
}

.page.community.simulator.portal {
    display: flex;
    flex-direction: column;
}

.page.community.simulator.portal .tabs {
    height: 30px;
    margin: 10px 0;
    display: flex;
    justify-content: space-between;
    padding: 0 10px;
}

.page.community.simulator.portal .tabs>div {
    width: 33.3%;
    align-items: center;
    display: flex;
}

.page.community.simulator.portal .tabs>div:nth-child(1) {
    justify-content: flex-start;
}

.page.community.simulator.portal .tabs>div:nth-child(2) {
    justify-content: center;
}

.page.community.simulator.portal .tabs>div:nth-child(3) {
    justify-content: flex-end;
}

.page.community.simulator.portal .tags {
    margin: 5px 10px;
    overflow: hidden;
    text-wrap: nowrap;
    min-height: 22px;
}

.page.community.simulator.portal .tags::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.page.community.simulator.portal .tags::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.community.simulator.portal .tags::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.community.simulator.portal .question-answers {
    flex-grow: 1;
    padding: 5px;
    overflow-y: auto;
}

.page.community.simulator.portal .question-answers::-webkit-scrollbar {
    width: 4px;
}

.page.community.simulator.portal .question-answers::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.community.simulator.portal .question-answers::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.community.simulator.portal .question-answers .item {
    margin-top: 10px;
    background-color: #fafafa;
    padding: 10px;
    border-radius: 5px;
}

.page.community.simulator.portal .question-answers .item:hover {
    background-color: #c6e3fa 3b;
}

.page.community.simulator.portal .question-answers .item .title {
    cursor: pointer;
    color: #444;
    font-size: 16px;
    font-weight: 600;
}

.page.community.simulator.portal .question-answers .item .owner {
    display: flex;
    align-items: center;
    padding: 2px 0;
    cursor: pointer;
}

.page.community.simulator.portal .question-answers .item .owner .ant-image {
    margin-right: 5px;
}

.page.community.simulator.portal .question-answers .item .owner .ant-image img {
    border-radius: 100%;
    border: 1px solid #eee;
}

.page.community.simulator.portal .question-answers .item .content {
    cursor: pointer;
    max-height: 150px;
    overflow-y: hidden;
    color: #444;
    white-space: break-spaces;
    word-wrap: break-word;
}

.page.community.simulator.portal .question-answers .item .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.community.simulator.portal .question-answers .item .footer span {
    color: #aaa;
    font-size: 12px;
}

.page.community.simulator.portal .question-answers .item .footer span:before {
    display: inline-block;
    padding: 0 5px;
    content: "·";
}

.page.community.simulator.portal .question-answers .item .footer span:nth-child(1):before {
    padding: 0;
    content: "";
}

.page.community.simulator.portal .question-answers .item .actions {
    display: flex;
    justify-content: space-between;
    color: #aaa;
    align-items: center;
}

.page.community.simulator.portal .question-answers .item .actions .info span {
    font-size: 12px;
}

.page.community.simulator.portal .question-answers .item .actions .info span:before {
    display: inline-block;
    padding: 0 5px;
    content: "·";
}

.page.community.simulator.portal .question-answers .item .actions .info span:nth-child(1):before {
    padding: 0;
    content: "";
}

.page.community.simulator.make-question {
    display: flex;
    flex-direction: column;
}

.page.community.simulator.make-question .header {
    display: flex;
    height: 50px;
}

.page.community.simulator.make-question .header>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 33.3%;
}

.page.community.simulator.make-question .header>div:nth-child(1) {
    justify-content: flex-start;
}

.page.community.simulator.make-question .header>div:nth-child(2) {
    justify-content: center;
}

.page.community.simulator.make-question .header>div:nth-child(3) {
    justify-content: flex-end;
}

.page.community.simulator.make-question .title {
    color: #444;
    border: none;
    font-size: 24px;
    border-bottom: 1px solid #eee;
    border-radius: 10px 10px 0 0;
    margin-bottom: 2px;
}

.page.community.simulator.make-question .title:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.page.community.simulator.make-question .editor {
    padding: 0;
    border: none;
    height: 660px;
}

.page.community.simulator.make-question .editor .ql-container,
.page.community.simulator.make-question .editor .ql-toolbar {
    border: none;
}

.page.community.simulator.make-question .footer {
    padding: 0 5px;
}

.page.community.simulator.make-question .footer .ant-select {
    width: 100%;
}

.page.community.simulator.question-detail {
    display: flex;
    flex-direction: column;
}

.page.community.simulator.question-detail .header {
    height: 30px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.community.simulator.question-detail .scoller {
    flex-grow: 1;
    overflow-y: auto;
}

.page.community.simulator.question-detail .scoller::-webkit-scrollbar {
    width: 4px;
}

.page.community.simulator.question-detail .scoller::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.community.simulator.question-detail .scoller::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.community.simulator.question-detail .question {
    padding: 5px;
}

.page.community.simulator.question-detail .question .title {
    color: #444;
    font-size: 18px;
    font-weight: 600;
    white-space: break-spaces;
    word-wrap: break-word;
    margin: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.community.simulator.question-detail .question .title .avatar {
    font-size: 12px;
}

.page.community.simulator.question-detail .question .content {
    font-weight: 400;
    max-height: 150px;
    overflow-y: auto;
    word-wrap: break-word;
}

.page.community.simulator.question-detail .question .content.expanded {
    max-height: none;
}

.page.community.simulator.question-detail .question .content p {
    margin: 8px 0;
}

.page.community.simulator.question-detail .question .header-info,
.page.community.simulator.question-detail .question .footer-info {
    color: #aaa;
    font-size: 12px;
    display: flex;
    justify-content: space-between;
}

.page.community.simulator.question-detail .question .header-info span:before,
.page.community.simulator.question-detail .question .footer-info span:before {
    display: inline-block;
    padding: 0 5px;
    content: "·";
}

.page.community.simulator.question-detail .question .header-info span:nth-child(1):before,
.page.community.simulator.question-detail .question .footer-info span:nth-child(1):before {
    padding: 0;
    content: "";
}

.page.community.simulator.question-detail .question .header-info .loadmore,
.page.community.simulator.question-detail .question .footer-info .loadmore {
    display: flex;
    justify-content: center;
    font-size: 12px;
    color: #aaa;
    cursor: pointer;
}

.page.community.simulator.question-detail .question .header-info .loadmore:hover,
.page.community.simulator.question-detail .question .footer-info .loadmore:hover {
    color: #026fd4;
}

.page.community.simulator.question-detail .question .actions {
    display: flex;
    justify-content: space-between;
    color: #aaa;
    align-items: center;
}

.page.community.simulator.question-detail .answers:before {
    display: block;
    height: 10px;
    background-color: #eee;
    content: "";
}

.page.community.simulator.question-detail .answers .actions {
    padding: 5px;
    display: flex;
    justify-content: space-between;
}

.page.community.simulator.question-detail .answers .item {
    cursor: pointer;
    padding-top: 5px;
}

.page.community.simulator.question-detail .answers .item:hover {
    background-color: #c6e3fa 3b;
}

.page.community.simulator.question-detail .answers .item:after {
    content: "";
    display: block;
    height: 10px;
    background-color: #eee;
}

.page.community.simulator.question-detail .answers .item .owner {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.page.community.simulator.question-detail .answers .item .owner .ant-image {
    margin-right: 5px;
}

.page.community.simulator.question-detail .answers .item .owner .ant-image img {
    border-radius: 100%;
    border: 1px solid #eee;
}

.page.community.simulator.question-detail .answers .item .content {
    padding: 0 10px;
    max-height: 150px;
    overflow-y: hidden;
    white-space: break-spaces;
    word-wrap: break-word;
}

.page.community.simulator.question-detail .answers .item .footer {
    padding: 0 10px;
    margin-bottom: 5px;
    color: #aaa;
    font-size: 12px;
}

.page.community.simulator.question-detail .answers .item .footer span:before {
    display: inline-block;
    padding: 0 5px;
    content: "·";
}

.page.community.simulator.question-detail .answers .item .footer span:nth-child(1):before {
    padding: 0;
    content: "";
}

.page.community.simulator.question-detail .answers .item .comments {
    padding: 10px;
}

.page.community.simulator.question-detail .answers .item .comments .input {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.page.community.simulator.question-detail .answers .item .comments .summary {
    display: flex;
    justify-content: space-between;
}

.page.community.simulator.question-detail.in-review .header {
    display: none;
}

.page.community.simulator.answer {
    display: flex;
    flex-direction: column;
}

.page.community.simulator.answer .header {
    display: flex;
    height: 50px;
}

.page.community.simulator.answer .header>div {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 33.3%;
}

.page.community.simulator.answer .header>div:nth-child(1) {
    justify-content: flex-start;
}

.page.community.simulator.answer .header>div:nth-child(2) {
    justify-content: center;
}

.page.community.simulator.answer .header>div:nth-child(3) {
    justify-content: flex-end;
}

.page.community.simulator.answer .title {
    border: none;
    font-size: 24px;
    border-bottom: 1px solid #eee;
    border-radius: 10px 10px 0 0;
    margin-bottom: 2px;
}

.page.community.simulator.answer .title:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.page.community.simulator.answer .editor {
    padding: 0;
    border: none;
    height: 500px;
}

.page.community.simulator.answer .editor .ql-container,
.page.community.simulator.answer .editor .ql-toolbar {
    border: none;
}

.page.community.simulator.answer-detail {
    display: flex;
    flex-direction: column;
    position: relative;
}

.page.community.simulator.answer-detail .header {
    height: 30px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.page.community.simulator.answer-detail .answer {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px 10px;
}

.page.community.simulator.answer-detail .answer::-webkit-scrollbar {
    width: 4px;
}

.page.community.simulator.answer-detail .answer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.community.simulator.answer-detail .answer::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.community.simulator.answer-detail .answer .title {
    color: #444;
    font-size: 18px;
    font-weight: 600;
    white-space: break-spaces;
    word-wrap: break-word;
    margin: 5px 0;
}

.page.community.simulator.answer-detail .answer .content {
    font-weight: 400;
    flex-grow: 1;
    overflow-y: auto;
    word-wrap: break-word;
}

.page.community.simulator.answer-detail .answer .content.expanded {
    max-height: none;
}

.page.community.simulator.answer-detail .answer .content p {
    margin: 8px 0;
}

.page.community.simulator.answer-detail .answer .owner {
    display: flex;
    align-items: center;
    padding: 5px 0;
    cursor: pointer;
}

.page.community.simulator.answer-detail .answer .owner .ant-image {
    margin-right: 5px;
}

.page.community.simulator.answer-detail .answer .owner .ant-image img {
    border-radius: 100%;
    border: 1px solid #eee;
}

.page.community.simulator.answer-detail .answer .header-info,
.page.community.simulator.answer-detail .answer .footer-info {
    color: #aaa;
    font-size: 12px;
}

.page.community.simulator.answer-detail .answer .header-info span:before,
.page.community.simulator.answer-detail .answer .footer-info span:before {
    display: inline-block;
    padding: 0 5px;
    content: "·";
}

.page.community.simulator.answer-detail .answer .header-info span:nth-child(1):before,
.page.community.simulator.answer-detail .answer .footer-info span:nth-child(1):before {
    padding: 0;
    content: "";
}

.page.community.simulator.answer-detail .answer .comments .input {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
}

.page.community.simulator.answer-detail .answer .comments .summary {
    display: flex;
    justify-content: space-between;
}

.page.community.simulator.answer-detail .actions {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    color: #aaa;
    align-items: center;
}

.page.community.simulator.answer-detail.in-review .header {
    display: none;
}