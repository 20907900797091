.page.resource-entry-list .item-article {
    margin-bottom: 30px !important;
}

.page.resource-entry-list .item-article .entry-form-tab {
    background-color: #fafafa;
    border: 0;
    border-radius: 5px;
    padding: 0 15px;
}

.page.resource-entry-list .item-article .entry-form-tab .ant-tabs-tabpane {
    height: 480px;
    overflow-y: auto;
}

.page.resource-entry-list .item-article .content {
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.page.resource-entry-list .item-article .content form .ant-row.ant-form-item {
    margin-bottom: 15px;
}

.page.resource-entry-list .item-article .title-line {
    display: flex;
    align-items: center;
}

.page.resource-entry-list .item-article .title-line .title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 30px;
}

.page.resource-entry-list .item-article .title-line .ant-ribbon {
    top: 0;
}

.page.resource-entry-list .item-article .title-line .ant-ribbon .ant-ribbon-text button {
    padding: 0;
    height: auto;
    color: #fff;
}

.page.resource-entry-list .item-article .meta-line {
    margin: 10px 0;
}

.page.resource-entry-list .item-article .meta-line .publishAt,
.page.resource-entry-list .item-article .meta-line .type,
.page.resource-entry-list .item-article .meta-line .status,
.page.resource-entry-list .item-article .meta-line .index,
.page.resource-entry-list .item-article .meta-line .view-sum,
.page.resource-entry-list .item-article .meta-line .state,
.page.resource-entry-list .item-article .meta-line .permission,
.page.resource-entry-list .item-article .meta-line .purchased_amount,
.page.resource-entry-list .item-article .meta-line .purchased_count {
    color: #888;
    font-size: 12px;
    margin-right: 20px;
}

.page.resource-entry-list .item-article .meta-line .purchased_amount {
    color: #fe5a3d;
}

.page.resource-entry-list .item-article .ant-list-item-extra {
    width: 272px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page.resource-entry-list .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper {
    width: auto;
}

.page.resource-entry-list .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
    width: 138px;
    height: 138px;
}