.xun-ai-hand-analysis .filter {
    margin: 20px 0;
}

.xun-ai-hand-analysis .filter .ant-form-item {
    margin: 10px 0;
    margin-right: 16px;
}

.xun-ai-hand-analysis .operation {
    display: flex;
    justify-content: space-between;
}

.xun-ai-hand-analysis .operation .repush-orders,
.xun-ai-hand-analysis .operation .check-action {
    flex-grow: 1;
}