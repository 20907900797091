.page-dashboard {
    display: flex;
}

.page-dashboard .buying-list {
    width: 350px;
    padding: 5px;
}

.page-dashboard .search-user {
    flex-grow: 1;
    padding: 5px;
}

.ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-title {
    margin: 0;
    margin-bottom: 4px;
}