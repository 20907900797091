.invite-code.reward-convert-item {
    background: url("../../images/marketing_plan_reward_bg.png") center no-repeat;
    background-size: contain;
    width: 340px;
    height: 120px;
    padding: 30px;
    position: relative;
    transform: scale(0.95);
}

.invite-code.reward-convert-item .title {
    margin: 0;
}

.invite-code.reward-convert-item .coin .cost {
    font-size: 18px;
    display: inline-block;
    margin-right: 5px;
}

.invite-code.reward-convert-item .business-no {
    position: absolute;
    top: 10px;
    left: 32px;
    color: #aaa;
}

.invite-code.reward-convert-item .reward-logo {
    position: absolute;
    right: 0;
    bottom: 0;
}

.invite-code.reward-convert-item .reward-logo .image {
    max-height: 90px;
    max-width: 100px;
}

.invite-code.reward-convert-item .footer {
    position: absolute;
    bottom: 10px;
}