.page.operation.notification.message .review {
    flex-grow: 1;
    height: 120px;
    overflow-y: auto;
}

.page.operation.notification.message .review .content {
    margin: 14px 0;
}

.page.operation.notification.message .review .content img {
    width: 100%;
}