.page.pricing .infos {
    display: flex;
    flex-wrap: wrap;
}

.page.pricing .infos .newcommer-form {
    border-radius: 20px;
    margin: 10px;
    width: 350px;
}

.page.pricing .infos .newcommer-form.add-block {
    cursor: pointer;
}

.page.pricing .infos .newcommer-form.add-block .ant-card-body {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 48px;
    height: 100%;
    color: #1890ff;
}

.page.pricing .tab .actions {
    margin: 10px;
    margin-bottom: 20px;
}

.page.pricing .tab form {
    display: flex !important;
}

.page.pricing .tab form .basic-spec {
    width: 400px;
}

.page.pricing .tab form .channel-spec {
    width: calc(100% - 400px);
    display: flex;
    flex-wrap: wrap;
}

.page.pricing .tab form .channel-spec .channel-card {
    margin: 0 20px 20px 20px;
    width: 400px;
}

.page.pricing .ant-form-text {
    font-weight: 600;
}