.page.nineton-logs .filter {
    margin: 20px 0;
}
.page.nineton-logs .operation {
    display: flex;
    justify-content: space-between;
}
.page.nineton-logs .operation .repush-orders, .page.nineton-logs .operation .check-action {
    flex-grow: 1;
}
