.page.cms-articles {
  margin-bottom: 20px;
}

.page.cms-articles .actions {
  margin-bottom: 15px;
}

.page.cms-articles .filter {
  height: 60px;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.page.cms-articles .filter .title-input {
  width: 400px;
}

.page.cms-articles .item-article {
  margin-bottom: 30px !important;
}

.page.cms-articles .item-article .content {
  min-height: 50px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.page.cms-articles .item-article .ant-list-item-extra {
  width: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page.cms-articles .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper {
  width: auto;
}

.page.cms-articles .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
  width: 138px;
  height: 138px;
}

.page.cms-articles .item-article .title-line {
  display: flex;
  align-items: center;
}

.page.cms-articles .item-article .title-line .title {
  font-size: 18px;
  font-weight: 600;
  margin-right: 30px;
}

.page.cms-articles .item-article .title-line .ant-ribbon {
  top: 0;
}

.page.cms-articles .item-article .title-line .ant-ribbon .ant-ribbon-text button {
  padding: 0;
  height: auto;
  color: #fff;
}

.page.cms-articles .item-article .meta-line {
  margin: 10px 0;
}

.page.cms-articles .item-article .meta-line .publishAt,
.page.cms-articles .item-article .meta-line .type,
.page.cms-articles .item-article .meta-line .status,
.page.cms-articles .item-article .meta-line .index,
.page.cms-articles .item-article .meta-line .view-sum {
  color: #888;
  font-size: 12px;
  margin-right: 20px;
}

.page.cms-articles .item-article .ant-list-item-action>li button.ant-btn-link {
  padding: 4px 0;
}