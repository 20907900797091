.acupoint-detect-records .filter {
    margin: 20px 0;
}

.acupoint-detect-records .filter .ant-form-item {
    margin: 10px 0;
    margin-right: 16px;
}

.acupoint-detect-records .operation {
    display: flex;
    justify-content: space-between;
}

.acupoint-detect-records .operation .repush-orders,
.acupoint-detect-records .operation .check-action {
    flex-grow: 1;
}