/* This file is for your main application css. */
/* LiveView specific classes for your customizations */
/* @import "~antd/dist/antd.css"; */

body {
  min-width: 1440px
}

main {
  flex-direction: column;
  display: flex;
  height: 100vh;
  overflow: auto;
}

main>.module,
main>.page {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}



main>.module .side-menu {
  /* margin-right: 10px; */
  /* max-height: calc(100vh - 125px); */
  overflow-y: auto;
  /* width: 260px; */
  /* flex: 1 1 auto; */
}

main>.module .side-menu::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

main>.module .side-menu::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
}

main>.module .side-menu::-webkit-scrollbar-track {
  border-radius: 0;
  background: rgba(0, 0, 0, 0.05);
}


main>.module .content {
  flex-grow: 1;
  padding-left: 10px;
  /* max-height: calc(100vh - 125px); */
  /* overflow: auto; */
  /* margin-bottom: 20px; */
  max-width: calc(100vw - 280px);
}

.cs-message__sent-time {
  display: block !important;
}

.cs-message__sender-name {
  display: block !important;
}

.cs-message--outgoing .cs-message__content {
  background-color: #f0f0f0 !important;
}

.cs-conversation__content {
  margin-right: 0.5em;
}

.customer-service-online-list.anonymous {
  opacity: 0.6;
}

.page.resource-illness,
.page.resource-acupoint,
.page.resource-meridian,
.page.resource-herb,
.page.resource-food {
  max-width: calc(100vw - 310px);
}

.module .content .page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 120px);
}

/* 
* {
  transition: all 0.5s;
} */