.register-records .filter {
    margin: 20px 0;
}

.register-records .filter .ant-form-item {
    margin-bottom: 10px;
    margin-right: 16px;
}

.register-records .filter form .ant-form-item.channel {
    width: 100%;
}

.register-records .filter form .ant-form-item.quick-date {
    width: 100%;
}

.register-records .operation {
    display: flex;
    justify-content: space-between;
}

.register-records .operation .repush-orders,
.register-records .operation .check-action {
    flex-grow: 1;
}