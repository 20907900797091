.view-multi-upload-form {
    min-height: 100px;
}

.view-multi-upload-form.active {
    background-color: #f5fcfe;
}

.view-multi-upload-form .content {
    position: relative;
}