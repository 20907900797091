.component.user-advance-filter {
    display: flex;
    flex-wrap: wrap;
    line-height: 40px;
    align-items: center;
}

.component.user-advance-filter label {
    display: inline-flex;
    align-items: center;
}

.component.user-advance-filter span.item {
    display: inline-flex;
    align-items: center;
}

.component.user-advance-filter span.item label.ant-radio-button-wrapper {
    margin: 2px 0;
}

.component.user-advance-filter span.item .label {
    white-space: nowrap;
}

.component.user-advance-filter.form p {
    display: block;
    line-height: 3em;
}