.page.cms-book {
    display: flex;
    flex-direction: column;
}

.page.cms-book .books {
    margin-top: 20px;
    overflow-y: auto;
}

.page.cms-book .books .infinite-scroll-component {
    display: flex;
    flex-wrap: wrap;
    gap: 0 50px;
    max-width: 1600px;
}

.page.cms-book .books .book-item {
    flex: 3 3;
    cursor: pointer;
}

.page.cms-book .books .book-item .cover {
    position: relative;
    width: 200px;
    height: 300px;
}

.page.cms-book .books .book-item .cover .price {
    position: absolute;
    top: 10px;
    left: 10px;
    color: #fff;
    text-shadow: #000 0 0 2px;
}

.page.cms-book .books .book-item .cover .view-count {
    position: absolute;
    right: 5px;
    top: 5px;
    color: #efefef;
    text-shadow: #000 0 0 2px;
    border-radius: 20px;
    border: 1px solid #aaa;
    padding: 2px 5px;
    background-color: rgba(230, 230, 230, 0.5);
}

.page.cms-book .books .book-item .cover .categories {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.page.cms-book .books .book-item .cover .categories .ant-tag {
    background-color: rgba(230, 230, 230, 0.5);
    color: #efefef;
    text-shadow: #000 0 0 2px;
}

.page.cms-book .books .book-item .title .recommend,
.page.cms-book .books .book-item .title .no-recommend {
    cursor: pointer;
}

.page.cms-book .books .book-item .title .no-recommend {
    display: none;
}

.page.cms-book .books .book-item:hover * {
    transition: all 500ms;
}

.page.cms-book .books .book-item:hover .title .no-recommend {
    display: inline;
}

.page.cms-book .books .book-item:hover .view-count {
    background-color: #fff;
}

.page.cms-book .books .book-item:hover .categories .ant-tag {
    background-color: #fff;
    text-shadow: #000 1px 1px 1px;
}