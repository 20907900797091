.information-tab.invite-code {}

.information-tab.invite-code.invite-code h1,
.information-tab.invite-code.invite-code h2,
.information-tab.invite-code.invite-code h3,
.information-tab.invite-code.invite-code h4,
.information-tab.invite-code.invite-code h5,
.information-tab.invite-code.invite-code h6,
.information-tab.invite-code.plan h1,
.information-tab.invite-code.plan h2,
.information-tab.invite-code.plan h3,
.information-tab.invite-code.plan h4,
.information-tab.invite-code.plan h5,
.information-tab.invite-code.plan h6 {
    margin: 0 0 5px;
}