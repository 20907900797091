.user-avatar-wrap {
    display: inline-flex !important;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.user-avatar-wrap .user-avatar .ant-avatar.online {
    box-shadow: 0 0 0 2px #0f0;
    border: 1px solid #fff;
}

.user-avatar-wrap .nickname {
    color: #1890ff;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}