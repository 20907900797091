.page.operation.launch-screen {
    margin: 10px;
}
.page.operation.launch-screen .launch-screen-group {
    display: flex;
    flex-direction: column;
    min-width: 100%;
    background-color: #fafafa;
    overflow-x: visible;
    margin-bottom: 20px;
    padding: 10px;
}
.page.operation.launch-screen .launch-screen-group:hover {
    background-color: #f8f8f8;
}
.page.operation.launch-screen .launch-screen-group .launches {
    display: flex;
    flex-grow: 1;
    overflow-x: scroll;
    overflow-y: auto;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item {
    margin: 0 5px;
    border-radius: 5px;
    height: 100%;
    position: relative;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item .delay-close {
    position: absolute;
    top: 35px;
    right: 15px;
    border-radius: 30px;
    border: 1px solid silver;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff a3;
    z-index: 5;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item .pic-uploader {
    background: transparent;
    flex-grow: 1;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item .pic-uploader .ant-upload-select {
    height: 100%;
    width: 100%;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item.add .cover {
    background: #fff;
}
.page.operation.launch-screen .launch-screen-group .launches .launch-item .cover {
    position: relative;
}
