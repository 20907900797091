.phone-wraper {
    width: 360px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-radius: 20px;
    border: 5px solid #fafafa;
    margin: 20px 0;
}

.phone-wraper .title {
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
}

.phone-wraper .body {
    background: #fafafa;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.phone-wraper .body .top-marquee {
    position: absolute;
    top: 0;
    margin: 15px;
    border-radius: 10px;
    background: #fff;
    padding: 5px;
}

.phone-wraper .body .alert {
    width: 250px;
    height: 200px;
    background: #fff;
    box-shadow: 0px 3px 3px 0px #e1e1e1;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
}

.phone-wraper .body .alert .btn-iknow {
    width: 100%;
    background: #f8f8f8;
    border-radius: 5px;
    padding: 5px;
    text-align: center;
}

.phone-wraper .footer {
    height: 50px;
}