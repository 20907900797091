.page.resource-video-course-editor .ant-list-grid .item-article {
    display: flex !important;
}

.page.resource-video-course-editor .item-article {
    margin-bottom: 30px !important;
}

.page.resource-video-course-editor .item-article .content {
    margin-top: 12px;
    min-height: 50px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}

.page.resource-video-course-editor .item-article .content .ant-row.ant-form-item {
    margin-bottom: 5px;
}

.page.resource-video-course-editor .item-article .video-upload {
    margin-bottom: 10px;
}

.page.resource-video-course-editor .item-article .title-line {
    display: flex;
    align-items: center;
}

.page.resource-video-course-editor .item-article .title-line .title {
    font-size: 18px;
    font-weight: 600;
    margin-right: 30px;
}

.page.resource-video-course-editor .item-article .title-line .ant-ribbon {
    top: 0;
}

.page.resource-video-course-editor .item-article .title-line .ant-ribbon .ant-ribbon-text button {
    padding: 0;
    height: auto;
    color: #fff;
}

.page.resource-video-course-editor .item-article .meta-line {
    margin: 10px 0;
}

.page.resource-video-course-editor .item-article .meta-line .publishAt,
.page.resource-video-course-editor .item-article .meta-line .type,
.page.resource-video-course-editor .item-article .meta-line .status,
.page.resource-video-course-editor .item-article .meta-line .index,
.page.resource-video-course-editor .item-article .meta-line .view-sum,
.page.resource-video-course-editor .item-article .meta-line .state,
.page.resource-video-course-editor .item-article .meta-line .permission,
.page.resource-video-course-editor .item-article .meta-line .purchased_amount,
.page.resource-video-course-editor .item-article .meta-line .purchased_count {
    color: #888;
    font-size: 12px;
    margin-right: 20px;
}

.page.resource-video-course-editor .item-article .meta-line .purchased_amount {
    color: #fe5a3d;
}

.page.resource-video-course-editor .item-article .ant-list-item-extra {
    width: 272px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page.resource-video-course-editor .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper {
    width: auto;
}

.page.resource-video-course-editor .item-article .ant-list-item-extra .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
    width: 138px;
    height: 138px;
}

.page.resource-video-course-editor img.cover {
    object-fit: cover;
}