.page.operation.post .post-item .title {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.page.operation.post .post-item .title:hover {
    white-space: normal;
    overflow: auto;
    text-overflow: unset;
}

.page.operation.post .post-item .content-wrap {
    background: #fafafa;
    padding: 10px 10px 20px 10px;
    height: 400px;
    overflow-y: auto;
    white-space: break-spaces;
}

.page.operation.post .post-item .content-wrap::-webkit-scrollbar {
    width: 4px;
}

.page.operation.post .post-item .content-wrap::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.2);
}

.page.operation.post .post-item .content-wrap::-webkit-scrollbar-track {
    border-radius: 0;
    background: rgba(0, 0, 0, 0.1);
}

.page.operation.post .post-item .content-wrap:hover::-webkit-scrollbar {
    width: 10px;
}

.page.operation.post .post-item .content-wrap .content img {
    width: 100%;
}

.page.operation.post .post-item .content-wrap .content p {
    overflow-wrap: break-word;
}