.component.media-viewer {
    border: 4px solid #f4d6a6;
    border-radius: 5px;
    padding: 5px;
}

.component.media-viewer.has-update {
    border-color: #188fff 1c;
}

.component.media-viewer .ant-tabs-tab+.ant-tabs-tab {
    margin-left: 15px;
}

.component.media-viewer .group-content {
    overflow-y: auto;
}

.component.media-viewer .group-content .function-block {
    margin: "2px 10px";
}

.component.media-viewer .group-content .function-block .left-move,
.component.media-viewer .group-content .function-block .right-move {
    flex-grow: 1;
    background-color: #2da8fa 1f;
    border: 0;
    color: #1890ff;
}

.component.media-viewer .group-content .function-block .add {
    flex-grow: 1;
    background-color: #faab2d 1f;
    border: 0;
    color: #a7792f;
}

.component.media-viewer .group-content .function-block .save {
    background-color: #188fff 1c;
    border: 0;
    color: #026fd4;
}

.component.media-viewer .group-content .function-block .delete {
    background-color: #ff4d50 12;
    border: 0;
    color: #d40004;
}

.component.media-viewer .group-content .delete-group {
    border: 2px solid #f14d4d;
    text-align: center;
    background-color: #f14d4d;
    color: #fff;
    padding: 4px;
    margin: 4px;
    cursor: pointer;
}

.component.media-viewer .group-content .save-group {
    border: 2px solid #dcb677;
    text-align: center;
    background-color: #dcb677;
    color: #fff;
    padding: 4px;
    margin: 4px;
    cursor: pointer;
}

.component.media-viewer .group-content .add-block {
    border: 2px dashed #dcb677;
    text-align: center;
    color: #dcb677;
    padding: 4px;
    margin: 4px;
    cursor: pointer;
}

.component.media-viewer .group-content .add-block-line {
    color: #dcb677;
}

.component.media-viewer .group-content .block {
    line-height: 2em;
    margin-bottom: 10px;
}

.component.media-viewer .group-content .block.type-title .title {
    font-size: 22px;
}

.component.media-viewer .group-content .block.type-title .title .name::before {
    content: none !important;
}

.component.media-viewer .group-content .block.type-sub_title .title {
    font-size: 16px;
}

.component.media-viewer .group-content .block.type-sub_title .title .name::before {
    content: none !important;
}

.component.media-viewer .group-content .block.type-sub_title .title span.name {
    font-weight: 400;
    padding-left: 20px;
}

.component.media-viewer .group-content .block .title {
    text-indent: 0;
    font-size: 18px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 20px 5px 0;
    align-items: center;
}

.component.media-viewer .group-content .block .title.blank .name::before {
    content: "";
}

.component.media-viewer .group-content .block .title input.name {
    display: inline-block;
    border: none;
    font-size: 18px;
    font-weight: bold;
    flex-grow: 1;
    padding: 0;
    background: transparent;
    margin: 0 10px;
}

.component.media-viewer .group-content .block .title .name::before {
    content: "■";
    color: #dcb677;
    position: relative;
    right: -11px;
    top: 5px;
    z-index: -1;
}

.component.media-viewer .group-content .block .title .edit {
    display: flex;
    align-items: center;
}

.component.media-viewer .group-content .block .title .edit,
.component.media-viewer .group-content .block .title .ant-btn-link {
    color: #dcb677;
    white-space: nowrap;
}

.component.media-viewer .group-content .block .title audio {
    height: 32px;
    width: 250px;
}

.component.media-viewer .group-content .block .upload-picture .ant-upload-select-picture-card {
    width: 100%;
}

.component.media-viewer .group-content .block .ant-divider-horizontal {
    margin: 0;
}

.component.media-viewer .group-content .block .content {
    font-size: 16px;
    max-height: max-content;
    padding: 5px;
}

.component.media-viewer .group-content .block .content>video {
    width: 100%;
}

.component.media-viewer .group-content .block .content.type-illness .ant-select,
.component.media-viewer .group-content .block .content.type-acupoint .ant-select,
.component.media-viewer .group-content .block .content.type-meridian .ant-select,
.component.media-viewer .group-content .block .content.type-term_poetics .ant-select,
.component.media-viewer .group-content .block .content.type-internal_link .ant-select {
    width: 100%;
}