.user-information-card .meta .action span {
    display: inline-block;
    margin: 0 5px;
}

.user-information-card .meta .action span.kick-out {
    cursor: pointer;
}

.user-information-card .meta .action span.customer-service {
    cursor: pointer;
    color: #1890ff;
}

.user-information-card.in-modal .ant-card-body {
    padding: 0;
}