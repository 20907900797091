.page.operation.user-feed-back .ant-list-items {
    display: flex;
    flex-wrap: wrap;
}
.page.operation.user-feed-back .ant-list-items .ant-list-item {
    flex: 1 1 400px;
    display: flex;
    align-items: flex-start;
    background-color: #fafafa;
    margin: 5px;
    border-radius: 5px;
    border: 0;
    padding: 10px;
}
