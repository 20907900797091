.page.online-app-user .online-block {
    display: flex;
    flex-wrap: wrap;
}

.page.online-app-user .offline-block {
    display: flex;
    flex-wrap: wrap;
}

.page.online-app-user .offline-block .info,
.page.online-app-user .offline-block .avatar {
    opacity: 0.6;
}

.page.online-app-user .app-user-item {
    margin: 20px;
    width: 270px;
    display: flex;
    color: #333;
}

.page.online-app-user .app-user-item .info {
    margin-left: 15px;
    flex-grow: 1;
}

.page.online-app-user .app-user-item:hover .actions {
    display: flex;
}