.user-information-tabs .device-list .list-item .ant-list-item {
    flex-direction: column;
    align-items: flex-start;
}

.user-information-tabs .device-list .list-item .ant-list-item .title {
    display: flex;
    align-items: center;
}

.user-information-tabs .device-list .list-item .ant-list-item .title>* {
    margin-right: 10px;
}

.user-information-tabs .device-list .list-item .ant-list-item>div {
    margin-top: 10px;
}

.user-information-tabs .device-list .ref-user-list .ant-list-item {
    flex-direction: row;
    align-items: center;
}

.user-information-tabs .wechat-evidence-list .list-item .secondary {
    color: #aaa;
}