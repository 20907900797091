.page.trade-order-refund .filter {
    margin: 20px 0;
}

.page.trade-order-refund .filter .ant-form-item {
    margin-bottom: 10px;
    margin-right: 16px;
}

.page.trade-order-refund .filter form .ant-form-item.channel {
    width: 100%;
}

.page.trade-order-refund .filter form .ant-form-item.quick-date {
    width: 100%;
}

.page.trade-order-refund .operation {
    display: flex;
    justify-content: space-between;
}

.page.trade-order-refund .operation .repush-orders,
.page.trade-order-refund .operation .check-action {
    flex-grow: 1;
}

.page.trade-order-refund .icon {
    font-size: 18px;
}

.page.trade-order-refund tr.status-cancel {
    opacity: 0.5;
}

.page.trade-order-refund td.refund {
    font-weight: 500;
    color: #2b96f4;
}

.page.trade-order-refund td.total_refund {
    font-weight: 600;
    color: #eb2f96;
}